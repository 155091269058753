import { withPrefix } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import parse from "html-react-parser";
import styled from "styled-components";
import breakpoints from "../../utils/breakpoints";

import External from "../../assets/vectors/external.svg";

const Container = styled.div`
  padding: 10rem var(--grid-padding) 10.05rem;

  @media ${breakpoints.tabletSm} {
    padding-top: 12rem;
    padding-bottom: 20rem;
  }

  @media ${breakpoints.desktop} {
    padding-top: 13.9rem;
    padding-bottom: 25.15rem;
  }
`;

const GridItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: solid #2b4463;
  border-width: 0.1rem 0 0 0.1rem;
`;


const GridItem = styled.div`
  position: relative;
  width: 100%;
  @media ${breakpoints.tabletSm} {
    width: 50%;
  }
  flex: none;
  border-bottom: 0.1rem solid #2b4463;
  border-right: 0.1rem solid #2b4463;

  @media ${breakpoints.tablet} {
    width: 25%;
  }

  @media ${breakpoints.desktop} {
    width: 20%;
  }

  @media ${breakpoints.monitor} {
    width: 16.6666666667%;
  }

  &:nth-child(odd) {
    border-right: 0.1rem solid #2b4463;
  }
`;

const GridItemLogo = styled(GatsbyImage)`
  display: block;
  position: relative;
  width: 100%;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const GridItemText = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 1.3333333333;
  letter-spacing: 0.02em;
  background-color: var(--brand-blue);
  color: #fff;
  padding: 1.5rem;
  opacity: 0;
  transition: opacity 0.12s var(--ease-soft);

  @media ${breakpoints.tabletSm} {
    font-size: 1.2rem;
    padding: 2.2rem 2rem;
  }

  @media ${breakpoints.tablet} {
    padding: 2.5rem;
  }

  @media ${breakpoints.desktop} {
    padding: 3rem 2.9rem 3rem 3rem;
    font-size: 1.4rem;
    line-height: 1.4285714286;
  }

  &.is-active {
    opacity: 1;
    transition-duration: 0.24s;
  }

  .is-not-touch &:hover {
    opacity: 1;
    transition-duration: 0.24s;
  }

  @media ${breakpoints.tablet} {
  }
`;

const GridItemLink = styled.div`
  font-weight: 500;

  a {
    display: inline-flex;
    align-items: flex-start;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  svg {
    display: block;
    stroke: currentColor;
    width: 0.6rem;
    height: 0.6rem;
    margin-left: 0.4rem;
  }
`;

const GridItemDescription = styled.div`
  margin-bottom: 1.5rem;
`;

const GridItemExitedBy = styled.div`
  color: #fff;
  font-weight: 500;
`;

const Exited = styled.div`
  position: absolute;
  width: 73px;
  background: #5badb3;
  height: 30px;
  top: 20px;
  left: 0;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.32px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 300;
`;

const GridItemFounder = styled.div``;

const TriangleLeft = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 0;
  border-top: 15px solid transparent;
  border-right: 5px solid #fff;
  border-bottom: 15px solid transparent;
`;

export default function Portfolio({ gridItems }) {
  const [activeFocusId, setActiveFocusId] = useState(-1);
  const [isDragging, setIsDragging] = useState(false);
  const onContainerTouchMove = () => {
    setIsDragging(true);
    setActiveFocusId(-1);
  };

  const onContainerTouchEnd = () => {
    setTimeout(() => {
      setIsDragging(false);
    }, 10);
  };

  return (
    <Container
      onTouchMove={onContainerTouchMove}
      onTouchEnd={onContainerTouchEnd}
    >
      <GridItems>
        {gridItems.map((gridItem, i) => {
          return (
            <GridItem key={i}>
              {gridItem.hasExited && (
                <Exited>
                  EXITED
                  <TriangleLeft />
                </Exited>
              )}
              <GridItemLogo
                image={getImage(gridItem.logo.imageFile)}
                alt={gridItem.logo.alt}
              />
              <GridItemText
                className={
                  !isDragging && i === activeFocusId ? "is-active" : ""
                }
              >
                <GridItemLink>
                  <a
                    href={gridItem.company_url}
                    target="_blank"
                    rel="noreferrer"
                    onTouchEnd={e => {
                      if (i !== activeFocusId) {
                        e.preventDefault();
                      }

                      if (!isDragging) {
                        setActiveFocusId(i);
                      }
                    }}
                  >
                    {gridItem.title} <External />
                  </a>
                </GridItemLink>
                <GridItemDescription>
                  {parse(gridItem.description)}
                </GridItemDescription>
                <GridItemFounder>
                  Founded by {gridItem.founders}
                </GridItemFounder>
                {gridItem.exitedBy && (
                  <GridItemExitedBy>{gridItem.exitedBy}</GridItemExitedBy>
                )}
              </GridItemText>
            </GridItem>
          );
        })}
      </GridItems>
    </Container>
  );
}
